import * as React from 'react'
import { Container } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import renderAst from '../components/ast-components'

const shopifyArticles = ({ data: { file } }) => {
  return (
    <Layout>
      <Container py={10}>{renderAst(file.childHtmlRehype.htmlAst)}</Container>
    </Layout>
  )
}

export default shopifyArticles

export const query = graphql`
  query ($relPath: String!) {
    file(relativePath: { eq: $relPath }) {
      childHtmlRehype {
        html
        htmlAst
      }
    }
  }
`
